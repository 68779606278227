import IconLogo from '@/components/_rebrand/IconLogo';
import Shared from '@/components/modals/shared';
import SocialProofContent from '@/components/modals/shared/SocialProofContent';
import gtm from '@/lib/gtm';
import { FC, useState } from 'react';
import Styled from './components';
import ConfirmationCodeForm from './ConfirmationCodeForm';
import EmailPasswordForm from './EmailPasswordForm';
import { ManagedTrialConfirmation } from './ManagedTrialConfirmation';
import PersonalInfoForm, { FormStepFields } from './PersonalInfoForm';

interface SignupModalProps {
  preventClose?: boolean;
  onClose?: () => void;
  step1Heading?: string;
  step1Subheading?: string;
  step2Heading?: string;
  step2Subheading?: string;
  step3Heading?: string;
  step3Subheading?: string;
}

export interface SignupModalStep {
  heading?: string;
  subheading?: string;
}

const SignupModal: FC<SignupModalProps> = ({
  preventClose = false,
  onClose = () => { },
  step1Heading = 'Try Front for free',
  step1Subheading = 'Help us tailor the trial experience to your needs.',
  step2Heading = '14-days free, no credit card required',
  step2Subheading = 'You’re one step closer to delivering exceptional service at scale.',
  step3Heading = 'You’re almost there!',
  step3Subheading = 'We sent a six-digit code to your email. It will expire in 30 minutes. Keep this window open while checking your code.',
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const urlParams = new URLSearchParams(window.location.search);
  const overrideAlternateFlow = urlParams.get('override') === 'true';

  // Values for passing between steps
  const [userData, setUserData] = useState<FormStepFields | null>();

  return (
    <Styled.Modal name="Free Trial Modal" preventClose={preventClose} sendEvents={!preventClose} onClose={onClose}>
      <Shared.ModalWrapper>
        <Shared.FormColumn>
          <IconLogo width={75} height={25} />
          {currentStep === 1 && (
            <PersonalInfoForm
              heading={overrideAlternateFlow ? 'Create your free account' : step1Heading}
              subheading={overrideAlternateFlow ? 'Sign up for a 14-day free trial, no credit card required.' : step1Subheading}
              overrideAlternateFlow={overrideAlternateFlow}
              onFormSuccess={(initial) => {
                setUserData(initial);
                // Proceed to self-service trial flow if company size is 50 or less or override is true
                if (parseInt(initial.company_size__new_) <= 50 || overrideAlternateFlow) {
                  setCurrentStep(3);
                } else {
                  // Redirect to alternate forced demo flow if company size is 51 or more and override is false
                  setCurrentStep(2);
                }
              }}
            />
          )}

          {/*  */}
          {currentStep === 2 && userData && (() => {
            return (
              <ManagedTrialConfirmation
                initialFormValues={userData}
                onDemoClick={() => {
                  gtm.track('click', {
                    element_type: 'link',
                    element_label: 'Product Tour',
                    location: `Form Success - Managed Trial Confirmation Modal`,
                  });
                  onClose();
                }} />
            );
          })()}

          {currentStep === 3 && userData && (
            <EmailPasswordForm
              heading={step2Heading}
              subheading={step2Subheading}
              overrideAlternateFlow={overrideAlternateFlow}
              onFormSuccess={(values) => {
                setUserData(values);
                setCurrentStep(4);
              }}
              initialFormValues={userData}
            />
          )}

          {currentStep === 4 && userData && (
            <ConfirmationCodeForm
              heading={step3Heading}
              subheading={step3Subheading}
              overrideAlternateFlow={overrideAlternateFlow}
              initialFormValues={userData}
              onClose={onClose}
            />
          )}
        </Shared.FormColumn>
        <SocialProofContent />
      </Shared.ModalWrapper>
    </Styled.Modal>
  );
};

export default SignupModal;
