export const OPTIMIZELY_SNIPPET_ID = '20142684273';
export const OPTIMIZELY_DEMAND_GEN_SNIPPET_ID = '24778130342';

export const OPTIMIZELY_EVENTS = {
  ANY_GET_STARTED_MODAL_FORM_SUBMIT: 'get_started_or_trial_or_demo_form_success',
  CUSTOMIZED_TRIAL_SUCCESS: 'customized_trial_success',
  GET_STARTED_CONVERSION: 'get_started_modal_conversion',
  GET_STARTED_FORM_SUBMIT: 'get_started_modal_form_success',
  GET_STARTED_SELECTED_COMPANY_SIZE: 'get_started_modal_selected_company_size',
  GET_STARTED_TRIAL_SIGNUP: 'get_started_modal_trial_signup_success',
  GET_STARTED_TRIAL_SUBMIT: 'get_started_modal_trial_submit',
  HUBSPOT_FORM_SUBMIT: 'hubspot_form_success',
  PRIMARY_NAV_CTA_CLICK: 'primary_nav_cta_click',
  PRIMARY_NAV_DEMO_CTA_CLICK: 'primary_nav_demo_cta_click',
  PRIMARY_NAV_GET_STARTED_CTA_CLICK: 'primary_nav_get_started_cta_click',
  PRIMARY_NAV_TRIAL_CTA_CLICK: 'primary_nav_trial_cta_click',
  REQUEST_DEMO_SUCCESS: 'request_demo_form_success',
  RESOURCE_FORM_SUBMIT: 'resource_form_success',
  SCROLL_DEPTH_50PCT: 'scroll_depth_50pct',
  SCROLL_DEPTH_75PCT: 'scroll_depth_75pct',
  TRIAL_SIGNUP: 'trial_signup_success',
  TRIAL_SIGNUP_SECOND_STEP: 'trial_modal_second_step',
  TRIAL_SIGNUP_THIRD_STEP: 'trial_modal_third_step',
} as const;

const OPTIMIZELY_USER_ATTRIBUTE_NAMES = [
  'product_tour_user',
  'use_case_page_visitor',
  'clearbit_company_employee_range',
  'clearbit_company_industry',
  'clearbit_company_name',
  'clearbit_company_revenue_range',
  'clearbit_company_subindustry',
] as const;

export type OPTIMIZELY_USER_ATTRIBUTES = (typeof OPTIMIZELY_USER_ATTRIBUTE_NAMES)[number];

export const OPTIMIZELY_PAGES = {
  ANY_GET_STARTED_MODAL: 'get_started_or_trial_or_demo',
  DEMO_MODAL: 'demo_modal',
  GET_STARTED_DEMO_CONFIRMATION: 'get_started_modal_demo_confirmation',
  GET_STARTED_TRIAL_SIGNUP: 'get_started_trial_signup',
  TRIAL_MODAL: 'trial_modal',
  TRIAL_MODAL_STEP_TWO: 'trial_modal_second_step',
} as const;

export const OPTIMIZELY_TESTS = {
  homepageVideoTest: {
    campaignID: '23865810025',
    variants: {
      control: '23844820073',
      newVideo: '23825880453',
    },
  },
} satisfies {
  [key: string]: {
    campaignID: string;
    variants: {
      control: string;
      [key: string]: string;
    };
  };
};
