import ChiliPiperStep from '@/components/modals/DemoModal/ChiliPiperStep';
import FormStep, { FormStepFields } from '@/components/modals/DemoModal/FormStep';
import { DEMO_REQUESTED_SESSION_KEY } from '@/constants/session-storage';
import { ChiliPiperScript } from '@/lib/chili-piper';
import gtm from '@/lib/gtm';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { NextRouter } from 'next/router';
import { FC, useEffect, useState, useTransition } from 'react';
import { useSessionstorageState } from 'rooks';
import Shared from '../shared';
import SocialProofContent from '../shared/SocialProofContent';
import { GenericConfirmation } from './GenericConfirmation';

interface DemoModalContentProps {
  router: NextRouter | AppRouterInstance;
  onClose: () => void;
}

const DemoModalContent: FC<DemoModalContentProps> = ({ router, onClose }) => {
  const [showGenericConfirmation, setShowGenericConfirmation] = useState(false);
  const [userData, setUserData] = useState<FormStepFields | null>(null);
  const [isPayingCustomer, setIsPayingCustomer] = useState<boolean>(false);
  const [navigationTriggered, setNavigationTriggered] = useState(false);
  const [navigationPending, startNavigation] = useTransition();
  const [, setDidRequestDemo] = useSessionstorageState<boolean>(DEMO_REQUESTED_SESSION_KEY, false);

  // Workaround to close modal after navigation b/c AppRouter does not return a promise after .push()
  useEffect(() => {
    if (navigationPending) {
      setNavigationTriggered(true);
    }
    if (!navigationPending && navigationTriggered) {
      onClose();
    }
  }, [navigationPending, navigationTriggered]);

  const showChiliPiper = !!userData;

  return (
    <>
      <Shared.Modal
        name="Demo Modal"
        onClose={() => {
          onClose();
        }}
      >
        <Shared.ModalWrapper>
          <Shared.FormColumn>
            {showGenericConfirmation ? (
              <GenericConfirmation userData={userData!} onClose={onClose} />
            ) : showChiliPiper ? (
              <ChiliPiperStep
                formFields={userData!}
                isPayingCustomer={isPayingCustomer}
                showGenericConfirmation={() => setShowGenericConfirmation(true)}
                onDemoClick={() => {
                  gtm.track('click', {
                    element_type: 'link',
                    element_label: 'Product Tour',
                    location: `Form Success - Demo Modal`,
                  });

                  onClose();
                }}
              />
            ) : (
              <FormStep
                onSuccess={(values, _isPayingCustomer) => {
                  setDidRequestDemo(true);
                  setIsPayingCustomer(_isPayingCustomer);
                  setUserData(values);
                }}
              />
            )}
          </Shared.FormColumn>
          <SocialProofContent />
        </Shared.ModalWrapper>
      </Shared.Modal>
      {ChiliPiperScript}
    </>
  );
};

export default DemoModalContent;
