import { PrimaryInnerGlowButton } from '@/components/buttons/primary';
import Shared from '@/components/modals/shared';
import {
  HubspotFieldNames,
  HubspotFormIDs
} from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';
import { PRODUCT_TOUR_PAGE } from '@/constants/routes';
import attribution from '@/lib/attribution';
import gtm from '@/lib/gtm';
import hubspot from '@/lib/hubspot';
import logging from '@/lib/logging';
import optimizely from '@/lib/optimizely';
import Link from 'next/link';
import { FC, useEffect } from 'react';
import { FormStepFields } from './PersonalInfoForm';

interface ManagedTrialConfirmationProps {
  onDemoClick: () => void;
  initialFormValues: FormStepFields;
}

export const ManagedTrialConfirmation: FC<ManagedTrialConfirmationProps> = ({ onDemoClick, initialFormValues }) => {
  // Fire optimizely event on form view, wrap in useEffect to prevent firing on every render
  useEffect(() => {
    optimizely.event(OPTIMIZELY_EVENTS.CUSTOMIZED_TRIAL_SUCCESS);
    gtm.trackCustomSalesTrialSignup();
    const affiliate = attribution.getAffiliateFromCookie() || '';

    hubspot.submitForm(HubspotFormIDs.TrialSignup, {
      [HubspotFieldNames.Affiliate]: affiliate,
      [HubspotFieldNames.ContentName]: 'Trial Signup',
      [HubspotFieldNames.Email]: initialFormValues[HubspotFieldNames.Email],
      [HubspotFieldNames.FirstName]: initialFormValues[HubspotFieldNames.FirstName],
      [HubspotFieldNames.LastName]: initialFormValues[HubspotFieldNames.LastName],
      [HubspotFieldNames.CompanyName]: initialFormValues[HubspotFieldNames.CompanyName],
      [HubspotFieldNames.JobTitle]: initialFormValues[HubspotFieldNames.JobTitle],
      [HubspotFieldNames.CompanySize]: initialFormValues[HubspotFieldNames.CompanySize],
      [HubspotFieldNames.Industry]: initialFormValues[HubspotFieldNames.Industry],
      [HubspotFieldNames.Phone]: initialFormValues[HubspotFieldNames.Phone],
      [HubspotFieldNames.MarketingEmailOptOut]: String(initialFormValues[HubspotFieldNames.MarketingEmailOptOut]),
      [HubspotFieldNames.TrialType]: 'Customized Sales Trial',
    }).catch((e) => {
      logging.captureException(e);
    })

  }, [initialFormValues]);

  return (
    <div>
      <h2>We offer a customized trial experience for teams of your size.</h2>
      <br />
      <p className='margin-top'>A Front specialist will be in touch shortly to help you get started.</p>
      <p className='margin-top'>In the meantime, take Front for a spin with an interactive product tour.</p>
      <Shared.Footer>
        <Link className='center' href={PRODUCT_TOUR_PAGE} target='_blank'>
          <PrimaryInnerGlowButton as="a" onClick={onDemoClick} >
            Take a tour
          </PrimaryInnerGlowButton>
        </Link>
      </Shared.Footer>
    </div>
  );
};
