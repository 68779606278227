import { validatePhone } from '@/lib/phone';
import { checkInvalidCharacters, isPersonalEmail, isSharedEmail, validateEmail } from '@/lib/validation';
import { EMAIL_ERRORS } from './email';

/**
 * Forms
 */

export enum HubspotFormIDs {
  BlogSubscribe = 'd899bee6-5a6d-4390-94ca-5d94939cbf11',
  CustomerAdvocacy = '6d8438b2-35bb-47d1-b754-8e3f475d6633',
  CustomerReferrals = '0c78cb30-f0c3-477c-a11f-fc75c479218d',
  FrontChatBot = 'e285d870-63a4-498b-b838-95f426773244',
  Frontlines = 'd5aad29b-eb54-450c-8f2b-d143cb4136a3',
  GetStartedModalPartial = '5798ecc8-ac71-4de9-8a74-ffb1fb3da57d',
  Partners = '35c825f7-3d93-40d3-a307-0c0e29d17a67',
  RegionalEvent = '2dc8c5ed-fb41-4e36-8695-1e7096d35eb7',
  RequestDemo = '5e0c6fcd-1ea9-401a-bb67-3385d9c9fd03',
  ResourceChecklist = '5182a0d2-45e5-472d-9c6d-e083713dfbbe',
  ResourceEbook = '468a09c9-5cd3-4f7d-a517-1eff83d2c0d7',
  ResourceGuide = '90df1db5-2e6d-4bb5-8976-a902b1d88579',
  ResourceOnDemandProductWebinar = '6e05bbc4-f6a7-44c4-9130-47ddb719fb09',
  ResourceOnDemandWebinar = '6ee2d97f-6cb1-4b67-95ad-3d96da991a5b',
  ResourceProductWebinar = '1bef6c38-25ac-4621-9e38-0f24d275f1a3',
  ResourceWebinar = '3a3530cb-0bcb-49c8-b54d-b4961e6549a1',
  ResourceWhitepaper = '621c0ed0-a9af-4653-9710-313b37f4dda8',
  ResourceWorksheet = '90f747e4-aa95-4305-b887-b70ef0dc6318',
  Startups = 'b849007c-6ad1-4b7b-9062-db4980fec481',
  TrialSignup = '894b8d4a-863b-4f62-9a0b-8ec151153f30',
  WatchDemo = '69e7efab-41ed-47b6-a973-b93167db09df',
}

export enum HubspotFieldNames {
  AdvocacyOptInType = 'advocacy_opt_in_type',
  Affiliate = 'affiliate',
  BusinessNeed = 'business_need_self_declared',
  ChiliPiperFlowStatus = 'chili_piper_flow_status',
  CompanyDomain = 'domain',
  CompanyName = 'company',
  CompanySize = 'company_size__new_',
  ContentName = 'content_name',
  Country = 'country',
  CustomerStatus = 'customer_status__c',
  DoNotContact = 'do_not_contact',
  Email = 'email',
  FirstName = 'firstname',
  FormPageURL = 'page_url_form_',
  FullName = 'full_name',
  Gclid = 'hull_gclid',
  GoogleClientID = 'google_client_id',
  HowDidYouHearAboutUs = 'how_did_you_hear_about_front',
  Industry = 'industry__self_select_',
  JobTitle = 'jobtitle',
  LastName = 'lastname',
  LinkedInCompanyURL = 'company_linkedin_url__form',
  LogoApproval = 'logo_approval',
  MarketingEmailOptOut = 'marketing_updates_opt_out__forms_',
  OptimizelyExperiments = 'experiment_id',
  OptimizelyVariations = 'optimizely_variant_id',
  PartnerInterestReason = 'partner_interest_reason',
  PartnerType = 'partner_type',
  Phone = 'phone',
  PrimaryUsecase = 'primary_use_case',
  ReferralDetails = 'referral_details',
  Referrer = 'referrer_url',
  ReferrerCompanyName = 'referrer_company_name',
  ReferrerEmail = 'referrer_email_address',
  ReferrerFirstName = 'referrer_first_name',
  ReferrerFrontRelationship = 'referrer_front_relationship',
  ReferrerLastName = 'referrer_last_name',
  ResourceID = 'resource_id',
  StartupFunding = 'funding_received_to_date',
  StartupReferralPartner = 'startup_referral_partner',
  StartupReferralPartnerFreeform = 'startup_referral_partner__name_of_firm',
  StartupReferralPartnerFundingURL = 'startup_referral_partner__url_of_firm',
  TrialType = 'trial_type',
  UTMCampaign = 'utm_campaign',
  UTMContent = 'utm_content',
  UTMMedium = 'utm_medium',
  UTMSource = 'utm_source',
  UTMTerm = 'utm_term',
  WebinarFeedback = 'event_feedback',
  WebinarID = 'livestorm_event_id',
  Website = 'website',
}

export enum HubspotFieldLabels {
  AdvocacyOptInType = 'Which of these activities would you like to participate in? Select all that apply',
  BusinessNeed = 'Business need',
  CompanyName = 'Company',
  CompanySize = 'Company size',
  Country = 'Country',
  Email = 'Work email',
  FirstName = 'First name',
  FullName = 'Full name',
  HowDidYouHearAboutUs = 'How did you hear about Front?',
  Industry = 'Industry',
  JobTitle = 'Job title',
  LastName = 'Last name',
  LinkedInCompanyURL = 'LinkedIn company URL',
  LogoApproval = 'Do we have permission to use your logo on Front Marketing Materials?',
  PartnerInterestReason = 'Why are you interested in Front?',
  PartnerType = 'Type of partner',
  Phone = 'Phone number',
  PrimaryUsecase = 'Primary use case',
  ReferrerFrontRelationship = 'Your relationship to Front',
  StartupFunding = 'Funding received to date',
  StartupReferralPartner = 'Referral partner',
  StartupReferralPartnerFreeform = 'If you do not work with a listed referral partner, please provide your startup accelerator, incubator or venture capital firm',
  StartupReferralPartnerFundingURL = 'Please provide a link to funding page profile (i.e. Crunchbase, Pitchbook, VC or Accelerator page)',
  Website = 'Website',
}

export const PrefillableHubspotFields = [
  HubspotFieldNames.Email,
  HubspotFieldNames.FirstName,
  HubspotFieldNames.LastName,
  HubspotFieldNames.CompanyName,
  HubspotFieldNames.JobTitle,
  HubspotFieldNames.CompanySize,
  HubspotFieldNames.Phone,
  HubspotFieldNames.Industry,
] as HubspotFieldNames[];

export const HubspotCompanySizeOptions = {
  XSmall: '1',
  Small: '11',
  Medium: '51',
  Large: '101',
  XLarge: '151',
  XXLarge: '251',
  XXXLarge: '1001',
} as const;

export const HubspotCompanySizeDropdownOptions = {
  '1 - 10 employees': HubspotCompanySizeOptions.XSmall,
  '11 - 50 employees': HubspotCompanySizeOptions.Small,
  '51 - 100 employees': HubspotCompanySizeOptions.Medium,
  '101 - 150 employees': HubspotCompanySizeOptions.Large,
  '151 - 250 employees': HubspotCompanySizeOptions.XLarge,
  '251 - 1,000 employees': HubspotCompanySizeOptions.XXLarge,
  '1,001+ employees': HubspotCompanySizeOptions.XXXLarge,
} as const;

export const HubspotIndustryDropdownOptions = {
  'Technology': 'Technology',
  'Logistics': 'Logistics',
  'Manufacturing': 'Manufacturing',
  'Professional Services': 'Professional Services',
  'Financial Services': 'Financial Services',
  'Travel & Hospitality': 'Travel & Hospitality',
  'Healthcare': 'Healthcare',
  'Retail': 'Retail',
  'Non-profit': 'Non-profit',
  'Education': 'Education',
  'Real Estate': 'Real Estate',
  'Consumer Services': 'Consumer Services',
  'Other': 'Other',
} as const;

export const HubspotLogoApprovalOptions = {
  Yes: 'Yes',
  No: 'No',
  NotSure: 'Not sure',
} as const;

export const HubspotLogoApprovalDropdownOptions = {
  'Yes': HubspotLogoApprovalOptions.Yes,
  'No': HubspotLogoApprovalOptions.No,
  'Not sure': HubspotLogoApprovalOptions.NotSure,
};

export const HubspotBusinessNeedOptions = {
  Share: 'Manage shared email in one place',
  Collaborate: 'Improve visibility into response times, inbox volume & trends',
  Automate: 'Automate communication across channels',
} as const;

export const HubspotBusinessNeedDropdownOptions = {
  [HubspotBusinessNeedOptions.Share]: HubspotBusinessNeedOptions.Share,
  [HubspotBusinessNeedOptions.Collaborate]: HubspotBusinessNeedOptions.Collaborate,
  [HubspotBusinessNeedOptions.Automate]: HubspotBusinessNeedOptions.Automate,
} as const;

export const HubspotReferrerFrontRelationshipOptions = {
  Customer: 'Front Customer',
  Partner: 'Front Partner',
} as const;

export const HubspotReferrerFrontRelationshipDropdownOptions = {
  [HubspotReferrerFrontRelationshipOptions.Customer]: HubspotReferrerFrontRelationshipOptions.Customer,
  [HubspotReferrerFrontRelationshipOptions.Partner]: HubspotReferrerFrontRelationshipOptions.Partner,
} as const;

export const HubspotAdvocacyOptInTypeOptions = {
  BlogPost: 'Contributing to blog post',
  Testimonial: 'Participating in case study or video testimonial',
  Speaking: 'Speaking at an event',
  Review: 'Writing a review',
  Research: 'Product or market research',
  Referral: 'Joining our reference program',
  Social: 'Sharing Front content on social media',
  Hosting: 'Hosting a user group',
  PR: 'Participating in PR opportunities',
} as const;

export const HubspotPrimaryUsecaseOptions = {
  Deliver: 'Deliver customer support across channels',
  Manage: 'Manage shared email inboxes',
  Streamline: 'Streamline client service and account management',
  Handle: 'Handle internal requests (HR, IT, etc)',
} as const;

export const HubspotPrimaryUsecaseDropdownOptions = {
  'Deliver customer support across channels': HubspotPrimaryUsecaseOptions.Deliver,
  'Manage shared email inboxes': HubspotPrimaryUsecaseOptions.Manage,
  'Streamline client service and account management': HubspotPrimaryUsecaseOptions.Streamline,
  'Handle internal requests (HR, IT, etc)': HubspotPrimaryUsecaseOptions.Handle,
};

export const HubspotChiliPiperFlowStatusOptions = {
  // User booked a date/time for a meeting
  Booked: 'Success - booked meeting for later',
  // Not in use at this time
  Connected: 'Success - connected via Concierge Live',
  // User is a paying customer (via HS) and Chili Piper was not shown to the user
  ExistingCustomer: 'Neutral - existing customer',
  // User closed the Chili Piper modal w/o booking
  Exited: 'Failure - exited flow after form submission',
  // User did not take action
  Expired: 'Failure - time expired after form submission',
  // Not in use at this time
  FailedToConnect: 'Failure - unsuccessful connection via Concierge Live',
  // User requested a sales rep to manually reach out
  RequestOtherTime: 'Neutral - did not find a time that works',
  // User requested a live call
  RequestedCall: 'Neutral - Prospect selected talk now',
} as const;

export const HubspotStartupFundingDropdownOptions = {
  'None': 'None',
  '<$100,000': '<$100,000',
  '$100,000 - $1M': '$100,000 - $1M',
  '$1M - $10M': '$1M - $10M',
  '$10M+': '$10M+',
} as const;

export const HubspotStartupReferralPartnerDropdownOptions = {
  'Agoranov': 'Agoranov',
  'Antler': 'Antler',
  'Aurelia Ventures': 'Aurelia Ventures',
  'Charles River Ventures (CRV)': 'Charles River Ventures (CRV)',
  'Clemta': 'Clemta',
  'Culttech Accelerator': 'Culttech Accelerator',
  'ecompassport': 'ecompassport',
  'DIFC': 'DIFC',
  'FounderPass': 'FounderPass',
  'Freelance Stack': 'Freelance Stack',
  'Join Secret': 'Join Secret',
  'Kima Ventures': 'Kima Ventures',
  'M25 VC': 'M25 VC',
  'Mercury': 'Mercury',
  'NFX': 'NFX',
  'Sequoia': 'Sequoia',
  'Startup Stack': 'Startup Stack',
  'SubscriptionPro': 'SubscriptionPro',
  'Techspace': 'Techspace',
  'University of Queensland Ventures (UQ Ventures)': 'University of Queensland Ventures (UQ Ventures)',
  'Utiliti Ventures': 'Utiliti Ventures',
  'Weekend Fund': 'Weekend Fund',
  'Y Combinator': 'Y Combinator',
  'Other (Please provide)': 'Other (Please provide)',
} as const;

/**
 * Field Validation
 */

export const HubspotFieldValidators = {
  [HubspotFieldNames.Email]: (
    value: string,
    options: { required: boolean; allowPersonalEmail: boolean; allowSharedEmail: boolean },
  ) => {
    const label = HubspotFieldLabels.Email;

    if (options.required && !value) {
      return `${label} is required`;
    }

    if (!validateEmail(value)) {
      return `Invalid ${label.toLowerCase()}`;
    }

    if (typeof options.allowPersonalEmail === 'boolean' && !options.allowPersonalEmail && isPersonalEmail(value)) {
      return EMAIL_ERRORS.WORK_EMAIL_REQUIRED;
    }

    if (typeof options.allowPersonalEmail === 'boolean' && !options.allowSharedEmail && isSharedEmail(value)) {
      return EMAIL_ERRORS.PERSONAL_WORK_EMAIL_REQUIRED;
    }
  },

  [HubspotFieldNames.FirstName]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.FirstName;

    if (options.required && !value) {
      return `${label} is required`;
    }

    if (!checkInvalidCharacters(value)) {
      return `${label} contains invalid characters`;
    }
  },

  [HubspotFieldNames.LastName]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.LastName;

    if (options.required && !value) {
      return `${label} is required`;
    }

    if (!checkInvalidCharacters(value)) {
      return `${label} contains invalid characters`;
    }
  },

  [HubspotFieldNames.FullName]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.FullName;

    if (options.required && !value) {
      return `${label} is required`;
    }

    if (!checkInvalidCharacters(value)) {
      return `${label} contains invalid characters`;
    }

    if (!/\s/g.test(value)) {
      return `Please enter your full name`;
    }
  },

  [HubspotFieldNames.CompanyName]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.CompanyName;

    if (options.required && !value) {
      return `${label} is required`;
    }

    if (!checkInvalidCharacters(value)) {
      return `${label} contains invalid characters`;
    }
  },

  [HubspotFieldNames.JobTitle]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.JobTitle;

    if (options.required && !value) {
      return `${label} is required`;
    }

    if (value && !checkInvalidCharacters(value)) {
      return `${label} contains invalid characters`;
    }
  },

  [HubspotFieldNames.Phone]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.Phone;

    if (options.required && (!value || value.replace(/\D/g, '').length === 0)) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    if (!validatePhone(value)) {
      return `${label} is not valid`;
    }
  },

  [HubspotFieldNames.CompanySize]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.CompanySize;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotCompanySizeOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.Industry]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.Industry;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotIndustryDropdownOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.BusinessNeed]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.BusinessNeed;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotBusinessNeedOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.PrimaryUsecase]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.PrimaryUsecase;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotPrimaryUsecaseOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.Website]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.Website;

    if (options.required && !value) {
      return `${label} is required`;
    }
  },

  [HubspotFieldNames.LinkedInCompanyURL]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.LinkedInCompanyURL;

    if (options.required && !value) {
      return `${label} is required`;
    }
  },

  [HubspotFieldNames.Country]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.Country;

    if (options.required && !value) {
      return `${label} is required`;
    }
  },

  [HubspotFieldNames.PartnerType]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.PartnerType;

    if (options.required && !value) {
      return `${label} is required`;
    }
  },

  [HubspotFieldNames.PartnerInterestReason]: (value: string, options: { required: boolean }) => {
    if (options.required && !value) {
      return `A reason is required`;
    }
  },

  [HubspotFieldNames.LogoApproval]: (value: string, options: { required: boolean }) => {
    if (options.required && !value) {
      return `Please let us know if we may use your logo`;
    }
  },

  [HubspotFieldNames.ReferrerFrontRelationship]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.ReferrerFrontRelationship;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotReferrerFrontRelationshipOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.ReferralDetails]: (value: string, options: { required: boolean }) => {
    if (options.required && !value) {
      return `Please provide details`;
    }

    if (value.length > 255) {
      return `Please provide fewer details (max 255 characters)`;
    }
  },

  [HubspotFieldNames.StartupFunding]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.StartupFunding;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotStartupFundingDropdownOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.StartupReferralPartner]: (value: string, options: { required: boolean }) => {
    const label = HubspotFieldLabels.StartupReferralPartner;

    if (!value && options.required) {
      return `${label} is required`;
    }

    if (!value) {
      return;
    }

    const match = (Object.values(HubspotStartupReferralPartnerDropdownOptions) as string[]).includes(value);

    if (!match) {
      return `${label} not valid`;
    }
  },

  [HubspotFieldNames.StartupReferralPartnerFreeform]: (value: string, options: { required: boolean }) => {
    if (options.required && !value) {
      return `Please provide your startup accelerator, incubator or venture capital firm`;
    }
  },

  [HubspotFieldNames.StartupReferralPartnerFundingURL]: (value: string, options: { required: boolean }) => {
    if (options.required && !value) {
      return `Please provide a link`;
    }
  },
} as const;
