import Shared from '@/components/modals/shared';
import { SignupModalStep } from '@/components/pages/signup/Modal';
import SignupFullScreenLoader from '@/components/pages/signup/SignupFullScreenLoader';
import { GTM_VARIABLES } from '@/constants/gtm';
import {
  HubspotFieldNames,
  HubspotFieldValidators,
  HubspotFormIDs
} from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';
import frontBackend from '@/lib/api/front-backend';
import { SignupErrorResponse } from '@/lib/api/front-backend/signup';
import { getAppDomain } from '@/lib/api/front-backend/utils';
import attribution from '@/lib/attribution';
import { useCustomFormik } from '@/lib/formik';
import gtm from '@/lib/gtm';
import hubspot from '@/lib/hubspot';
import logging from '@/lib/logging';
import optimizely from '@/lib/optimizely';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import CodeInput from './CodeInput';
import Styled from './components';
import { FormStepFields } from './PersonalInfoForm';

interface ConfirmationCodeFormProps extends SignupModalStep {
  initialFormValues: FormStepFields;
  onClose: () => void;
  overrideAlternateFlow: boolean;
}

const ConfirmationCodeForm: FC<ConfirmationCodeFormProps> = ({ heading, subheading, overrideAlternateFlow, initialFormValues, onClose }) => {
  const [error, setError] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  // Fire optimizely event on form view, wrap in useEffect to prevent firing on every render
  useEffect(() => {
    optimizely.event(OPTIMIZELY_EVENTS.TRIAL_SIGNUP_THIRD_STEP);
  }, []);

  const formik = useCustomFormik<FormStepFields & { code: string, password: string }>({
    enableReinitialize: false,
    initialValues: {
      password: '',
      code: '',
      ...initialFormValues,
    },
    onSubmit: (values, actions) => {
      setError('');

      const timeoutId = setTimeout(() => {
        setShowLoader(true);
      }, 2000);

      const affiliate = attribution.getAffiliateFromCookie() || '';

      frontBackend
        .signup({
          affiliate,
          code: values.code,
          company: values[HubspotFieldNames.CompanyName],
          email: values[HubspotFieldNames.Email],
          family_name: values[HubspotFieldNames.LastName],
          given_name: values[HubspotFieldNames.FirstName],
          industry: values[HubspotFieldNames.Industry],
          origin: window?.location?.href ?? '',
          password: values.password,
          size: values[HubspotFieldNames.CompanySize],
        })
        .then(({ data }) => {
          // TODO re-add autojoin logic
          setShowLoader(true);
          const props = {
            [GTM_VARIABLES.USER_EMAIL]: values[HubspotFieldNames.Email],
            [GTM_VARIABLES.USER_FIRST_NAME]: values[HubspotFieldNames.FirstName],
            [GTM_VARIABLES.USER_LAST_NAME]: values[HubspotFieldNames.LastName],
            affiliate,
            company: values[HubspotFieldNames.CompanyName],
            gclid: attribution.getAttributionFromCookie().gclid,
            industry: values[HubspotFieldNames.Industry],
            label: window.btoa(unescape(encodeURIComponent(data.first_admin_id ?? data.id ?? ''))),
            teammate_id: data.first_admin_id,
            marketing_email_opt_out: values[HubspotFieldNames.MarketingEmailOptOut],
          }

          hubspot
            .submitForm(HubspotFormIDs.TrialSignup, {
              [HubspotFieldNames.Affiliate]: affiliate,
              [HubspotFieldNames.ContentName]: 'Trial Signup',
              [HubspotFieldNames.Email]: values[HubspotFieldNames.Email],
              [HubspotFieldNames.FirstName]: values[HubspotFieldNames.FirstName],
              [HubspotFieldNames.LastName]: values[HubspotFieldNames.LastName],
              [HubspotFieldNames.CompanyName]: values[HubspotFieldNames.CompanyName],
              [HubspotFieldNames.JobTitle]: values[HubspotFieldNames.JobTitle],
              [HubspotFieldNames.Phone]: values[HubspotFieldNames.Phone],
              [HubspotFieldNames.CompanySize]: values[HubspotFieldNames.CompanySize],
              [HubspotFieldNames.Industry]: values[HubspotFieldNames.Industry],
              [HubspotFieldNames.MarketingEmailOptOut]: String(values[HubspotFieldNames.MarketingEmailOptOut]),
              [HubspotFieldNames.TrialType]: overrideAlternateFlow ? 'Forced Trial' : 'Standard Trial',
            })
            .catch((e) => {
              logging.captureException(e);
            })
            .finally(() => {
              // If we have the override=true flag, fire forced trial events
              overrideAlternateFlow ? gtm.trackForcedTrialSignup(props) : gtm.trackTrialSignup(props);

              optimizely.event(OPTIMIZELY_EVENTS.TRIAL_SIGNUP);
              setTimeout(() => {
                window.location.href = data.first_login_url ?? getAppDomain();
              }, 250);
            });
        })
        .catch((error: AxiosError<SignupErrorResponse>) => {
          clearTimeout(timeoutId);
          setShowLoader(false);

          logging.captureException(error);

          if (error.response?.data?.code === 'bad_validation_code') {
            actions.setFieldError('code', 'Incorrect validation code');
          } else if (error.response?.status === 429) {
            setError('Too many attempts, please try again later');
          } else {
            setError('An unexpected error occurred, please refresh and try again');
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.FirstName] = HubspotFieldValidators[HubspotFieldNames.FirstName](
        values[HubspotFieldNames.FirstName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.LastName] = HubspotFieldValidators[HubspotFieldNames.LastName](
        values[HubspotFieldNames.LastName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanyName] = HubspotFieldValidators[HubspotFieldNames.CompanyName](
        values[HubspotFieldNames.CompanyName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Industry] = HubspotFieldValidators[HubspotFieldNames.Industry](
        values[HubspotFieldNames.Industry],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.JobTitle] = HubspotFieldValidators[HubspotFieldNames.JobTitle](
        values[HubspotFieldNames.JobTitle],
        {
          required: true,
        },
      );

      errors['code'] = ((code) => {
        if (!code) {
          return 'Please enter the code sent to your email';
        }

        if (code.length !== 6) {
          return 'The code is 6 digits long';
        }
      })(values['code']);

      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
    validateOnBlur: false,
  });

  return (
    <>
      <div>
        <h2>{heading}</h2>
        {subheading ? <p>{subheading}</p> : null}

        <form onSubmit={formik.handleSubmit} noValidate>

          <Styled.CodeInputWrapper>
            <div>
              <CodeInput
                code={formik.values.code}
                length={6}
                onChange={(value) => formik.setFieldValue('code', value)}
                disabled={false}
                invalid={formik.touched.code && !!formik.errors.code}
                onFocus={(isInvalid) => {
                  if (formik.errors.code?.includes('Incorrect')) {
                    formik.setFieldValue('code', '');
                  }
                }}
              />

              {formik.touched.code && !!formik.errors.code ? <p>{formik.errors.code}</p> : null}
            </div>
          </Styled.CodeInputWrapper>

          {!!error ? <Styled.ErrorMessage>{error}</Styled.ErrorMessage> : null}

          <input type="submit" style={{ display: 'none' }} />

          <Shared.StyledPrimaryButton
            type="button"
            disabled={formik.isValidating || formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting ? 'Starting your trial...' : 'Start my trial'}
          </Shared.StyledPrimaryButton>
        </form>
      </div>

      {showLoader ? (
        <SignupFullScreenLoader firstName={formik.values[HubspotFieldNames.FirstName]} industry={formik.values[HubspotFieldNames.Industry]} />
      ) : null}
    </>
  );
};

export default ConfirmationCodeForm;
