import InputField from '@/components/forms/InputField';
import PhoneField from '@/components/forms/PhoneField';
import SelectField from '@/components/forms/SelectField';
import Shared from '@/components/modals/shared';
import { EMAIL_ERRORS } from '@/constants/email';
import {
  HubspotCompanySizeDropdownOptions,
  HubspotFieldLabels,
  HubspotFieldNames,
  HubspotFieldValidators,
  HubspotFormIDs,
  HubspotIndustryDropdownOptions
} from '@/constants/hubspot';
import { UserContext } from '@/context/user';
import attribution from '@/lib/attribution';
import { useCustomFormik } from '@/lib/formik';
import useEmailAvailability, { EmailAvailability } from '@/lib/hooks/useEmailAvailability';
import hubspot from '@/lib/hubspot';
import logging from '@/lib/logging';
import { isWorkEmail } from '@/lib/validation';
import { FC, useContext, useEffect, useState } from 'react';
export interface FormStepFields {
  [HubspotFieldNames.CompanyName]: string;
  [HubspotFieldNames.CompanySize]: string;
  [HubspotFieldNames.Email]: string;
  [HubspotFieldNames.FirstName]: string;
  [HubspotFieldNames.Industry]: string;
  [HubspotFieldNames.JobTitle]: string;
  [HubspotFieldNames.LastName]: string;
  [HubspotFieldNames.Phone]: string;
  [HubspotFieldNames.MarketingEmailOptOut]: boolean;
  password?: string;
}

interface PersonalInfoFormProps {
  heading: string;
  subheading: string;
  overrideAlternateFlow: boolean;
  onFormSuccess: (values: FormStepFields, showChiliPiper: boolean) => void;
}

const PersonalInfoForm: FC<PersonalInfoFormProps> = ({ heading, subheading, overrideAlternateFlow, onFormSuccess }) => {
  const { getTempUserData } = useContext(UserContext);
  const [emailStatus, setEmail] = useEmailAvailability();
  const [error, setError] = useState('');

  const formik = useCustomFormik<FormStepFields>({
    initialValues: {
      [HubspotFieldNames.CompanyName]: '',
      [HubspotFieldNames.FirstName]: '',
      [HubspotFieldNames.Industry]: '',
      [HubspotFieldNames.JobTitle]: '',
      [HubspotFieldNames.LastName]: '',
      [HubspotFieldNames.Phone]: '',
      [HubspotFieldNames.Email]: '',
      [HubspotFieldNames.CompanySize]: '',
      [HubspotFieldNames.MarketingEmailOptOut]: false,
    },
    onSubmit: (values, actions) => {
      setError('');


      const companySize = parseInt(values[HubspotFieldNames.CompanySize]);
      /*
       * Trial type for over 50 is 'Customized Sales Trial' - indicating the redirection to demo flow
       * Trial type in case of override flag is 'Forced Trial'
       * Only the below 50 company size is 'Standard Trial'
       */

      const trialType = overrideAlternateFlow ? 'Forced Trial' : companySize > 50 ? 'Customized Sales Trial' : 'Standard Trial';

      hubspot.submitForm(HubspotFormIDs.GetStartedModalPartial, {
        [HubspotFieldNames.Affiliate]: attribution.getAffiliateFromCookie(),
        [HubspotFieldNames.ContentName]: 'Trial Modal',
        [HubspotFieldNames.Email]: values[HubspotFieldNames.Email],
        [HubspotFieldNames.FirstName]: values[HubspotFieldNames.FirstName],
        [HubspotFieldNames.LastName]: values[HubspotFieldNames.LastName],
        [HubspotFieldNames.CompanyName]: values[HubspotFieldNames.CompanyName],
        [HubspotFieldNames.JobTitle]: values[HubspotFieldNames.JobTitle],
        [HubspotFieldNames.CompanySize]: values[HubspotFieldNames.CompanySize],
        [HubspotFieldNames.Industry]: values[HubspotFieldNames.Industry],
        [HubspotFieldNames.Phone]: values[HubspotFieldNames.Phone],
        [HubspotFieldNames.MarketingEmailOptOut]: String(values[HubspotFieldNames.MarketingEmailOptOut]),
        [HubspotFieldNames.TrialType]: trialType,
      }).catch((e) => {
        logging.captureException(e);
      }).finally(() => {
        actions.setSubmitting(false);
        onFormSuccess(values, false);
      });
    },
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.Email] = HubspotFieldValidators[HubspotFieldNames.Email](
        values[HubspotFieldNames.Email],
        {
          required: true,
          allowPersonalEmail: true,
          allowSharedEmail: true,
        },
      );

      switch (emailStatus) {
        case EmailAvailability.Available:
          break;
        case EmailAvailability.Unavailable:
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? EMAIL_ERRORS.EXISTS;
          break;
        case EmailAvailability.Error:
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? EMAIL_ERRORS.ERROR;
          break;
        default:
          // Prevent submitting without showing an error if there isn't one set already
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? '';
      }

      errors[HubspotFieldNames.FirstName] = HubspotFieldValidators[HubspotFieldNames.FirstName](
        values[HubspotFieldNames.FirstName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.LastName] = HubspotFieldValidators[HubspotFieldNames.LastName](
        values[HubspotFieldNames.LastName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanyName] = HubspotFieldValidators[HubspotFieldNames.CompanyName](
        values[HubspotFieldNames.CompanyName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Industry] = HubspotFieldValidators[HubspotFieldNames.Industry](
        values[HubspotFieldNames.Industry],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanySize] = HubspotFieldValidators[HubspotFieldNames.CompanySize](
        values[HubspotFieldNames.CompanySize],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.JobTitle] = HubspotFieldValidators[HubspotFieldNames.JobTitle](
        values[HubspotFieldNames.JobTitle],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Phone] = HubspotFieldValidators[HubspotFieldNames.Phone](
        values[HubspotFieldNames.Phone],
        {
          required: true,
        },
      );


      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    const data = getTempUserData();

    Object.keys(data).forEach((key) => {
      formik.setFieldValue(key, data[key]);
    });


  }, []);

  useEffect(() => {
    setEmail(formik.values[HubspotFieldNames.Email]);
  }, [formik.values[HubspotFieldNames.Email]]);

  useEffect(() => {
    if (emailStatus !== EmailAvailability.Untouched) {
      formik.setFieldTouched(HubspotFieldNames.Email, true, true);
    }
  }, [emailStatus]);

  const checkingEmail = emailStatus === EmailAvailability.Checking;
  const isNotWorkEmail = !isWorkEmail(formik.values[HubspotFieldNames.Email]);

  return (
    <>
      <h2>{heading}</h2>
      <p>{subheading}</p>

      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField
          type="email"
          name={HubspotFieldNames.Email}
          label={HubspotFieldLabels.Email}
          placeholder="you@company.com"
          value={formik.values[HubspotFieldNames.Email]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik.touched[HubspotFieldNames.Email] && !!formik.errors[HubspotFieldNames.Email]}
          errorMessage={formik.errors[HubspotFieldNames.Email] as string}
          warningMessage={isNotWorkEmail ? "It's best to sign up with your work email" : undefined}
          loading={checkingEmail}
          translucent
        />
        <fieldset>
          <InputField
            type="text"
            name={HubspotFieldNames.FirstName}
            label={HubspotFieldLabels.FirstName}
            placeholder="Jamie"
            value={formik.values[HubspotFieldNames.FirstName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.FirstName] && !!formik.errors[HubspotFieldNames.FirstName]}
            errorMessage={formik.errors[HubspotFieldNames.FirstName] as string}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.LastName}
            label={HubspotFieldLabels.LastName}
            placeholder="Smith"
            value={formik.values[HubspotFieldNames.LastName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.LastName] && !!formik.errors[HubspotFieldNames.LastName]}
            errorMessage={formik.errors[HubspotFieldNames.LastName] as string}
            translucent
          />
        </fieldset>

        <fieldset>
          <InputField
            type="text"
            name={HubspotFieldNames.CompanyName}
            label={HubspotFieldLabels.CompanyName}
            placeholder="Your Company"
            value={formik.values[HubspotFieldNames.CompanyName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.CompanyName] && !!formik.errors[HubspotFieldNames.CompanyName]}
            errorMessage={formik.errors[HubspotFieldNames.CompanyName] as string}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.JobTitle}
            label={HubspotFieldLabels.JobTitle}
            placeholder="e.g., Director of Operations"
            value={formik.values[HubspotFieldNames.JobTitle]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.JobTitle] && !!formik.errors[HubspotFieldNames.JobTitle]}
            errorMessage={formik.errors[HubspotFieldNames.JobTitle] as string}
            translucent
          />
        </fieldset>

        <fieldset>
          <SelectField
            name={HubspotFieldNames.Industry}
            label={HubspotFieldLabels.Industry}
            options={HubspotIndustryDropdownOptions}
            value={formik.values[HubspotFieldNames.Industry]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Industry] && !!formik.errors[HubspotFieldNames.Industry]}
            errorMessage={formik.errors[HubspotFieldNames.Industry] as string}
            translucent
          />
        </fieldset>

        <fieldset>
          <PhoneField
            name={HubspotFieldNames.Phone}
            label={HubspotFieldLabels.Phone}
            value={formik.values[HubspotFieldNames.Phone]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Phone] && !!formik.errors[HubspotFieldNames.Phone]}
            errorMessage={formik.errors[HubspotFieldNames.Phone] as string}
          />
          <SelectField
            name={HubspotFieldNames.CompanySize}
            label={HubspotFieldLabels.CompanySize}
            options={HubspotCompanySizeDropdownOptions}
            value={formik.values[HubspotFieldNames.CompanySize]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.CompanySize] && !!formik.errors[HubspotFieldNames.CompanySize]}
            errorMessage={formik.errors[HubspotFieldNames.CompanySize] as string}
            translucent
          />
        </fieldset>

        <input type="submit" style={{ display: 'none' }} />
      </form>

      <Shared.Footer>
        <span>{/* Leave empty */}</span>
        <div>
          <Shared.StyledPrimaryButton
            type="button"
            disabled={formik.isValidating || formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting ? 'Submitting...' : 'Submit'}
          </Shared.StyledPrimaryButton>
        </div>
      </Shared.Footer>
    </>
  );
};

export default PersonalInfoForm;
