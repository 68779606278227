import { FormStepFields as DemoFormStepFields } from '@/components/modals/DemoModal/FormStep';
import Step from '@/components/modals/shared/Step';
import { FormStepFields as PersonalInfoFormStepFields } from '@/components/pages/signup/PersonalInfoForm';
import {
  CUSTOMER_STORIES_HOME,
  PRODUCT_TOUR_PAGE,
  RESOURCES_HOME,
} from '@/constants/routes';
import gtm from '@/lib/gtm';
import Link from 'next/link';
import React from 'react';

interface GenericConfirmationProps {
  userData: PersonalInfoFormStepFields | DemoFormStepFields;
  onClose: () => void;
}

export const GenericConfirmation: React.FC<GenericConfirmationProps> = ({ userData, onClose }) => {
  return (
    <Step
      heading={`Thank you, ${userData?.firstname ?? 'friend'}!`}
      subheading="Here's what you can expect next."
    >
      <h4>We’ll be in touch</h4>

      <p>
        A product expert will call or email you soon to set up a time to chat. We’ll ask about your workflows
        to prepare a personalized demo that fits your team’s unique needs.
      </p>

      <p>In the meantime, there are a few ways to explore Front on your own.</p>

      <ul>
        <li>
          <p>
            <Link
              href={PRODUCT_TOUR_PAGE}
              onClick={() => {
                onClose();
                gtm.track('click', {
                  element_type: 'link',
                  element_label: 'Explore our product tour',
                  location: `Form Success - Demo Modal`,
                });
              }}
            >
              Explore our product tour &rarr;
            </Link>
          </p>
        </li>

        <li>
          <p>
            <Link
              href={RESOURCES_HOME}
              onClick={() => {
                onClose();
                gtm.track('click', {
                  element_type: 'link',
                  element_label: 'Check out our resource hub',
                  location: `Form Success - Demo Modal`,
                });
              }}
            >
              Check out our resource hub &rarr;
            </Link>
          </p>
        </li>

        <li>
          <p>
            <Link
              href={CUSTOMER_STORIES_HOME}
              onClick={() => {
                onClose();
                gtm.track('click', {
                  element_type: 'link',
                  element_label: 'Meet our customers',
                  location: `Form Success - Demo Modal`,
                });
              }}
            >
              Meet our customers &rarr;
            </Link>
          </p>
        </li>
      </ul>
    </Step>
  );
};
